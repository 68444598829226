<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="4"
        align="center"
      >
        <h1 class="display-2 primary--text">
          {{ $t('errors.404.title') }}
        </h1>
        <div class="subtitle-1">
          {{ $t('errors.404.subtitle') }}
        </div>
        <v-btn
          depressed
          color="primary"
          class="mt-12"
          @click="$router.push('/')"
        >
          {{ $t('errors.404.button') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('errors.404.meta.title')} — %s`
    }
  }
}
</script>
